@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz@6..12&display=swap");

@font-face {
  font-family: "MoonTime";
  src: url("../assets/FontsFree-Net-MoonTime-Regular-1.ttf") format("truetype");
}

body:has(.main-container-auth) {
  background-color: rgb(146, 62, 172);
}

.main-container-auth {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
}
.inner-container {
  background: rgb(211, 150, 255);
  width: 80%;
  display: flex;
}

.right-pane {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner-container .ant-form-item-label > label {
  color: #25007a;
  font-size: 18px;
  font-weight: 500;
}
.form-button {
  margin-left: 9.5rem;
  width: 50%;
  background-color: #9616ff;
}
.headding-text {
  margin-bottom: 3rem;
  margin-left: 5rem;
  font-size: 28px;
  color: #620062;
}
.submit-button {
  background-color: #8C52FF;
  color: white;
  border-color: transparent;
}

.submit-button:hover {
  color: #ffffff !important;
  border-color: transparent !important;
  background-color: #620062;
}
.main-container-auth-mobile {
  display: none;
}

@media only screen and (max-width: 767px) {
  .main-container-auth {
    display: none;
  }

  .main-container-auth-mobile {
    display: block;
    height: 100vh;
    background: url(../assets/Your\ paragraph\ text.png);
  }

  body:has(.main-container-auth-mobile) {
    background-color: transparent;
  }
  .handwrite-font {
    font-family: "MoonTime", cursive;
    color: #fba9ff;
    font-size: 100px;
    font-weight: 100;
    bottom: 40px;
    position: absolute;
  }
  .headding-text-mobile {
    color: white;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
    font-weight: 30;
    font-size: 30px;
  }

  .auth-container {
    display: flex;
    height: 100vh;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 2rem;
  }
  .auth-container .ant-input{
    border-radius: 15px;

  }
  .form-button{
    border-radius: 15px;
  }
  .submit-button{
    border-radius: 15px;
  }
}
