body {
  margin: 0;
  padding: 0;
}

.main_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #603F8B;
  
}

.box_container {
  display: flex;
  background-color: #D8B5D7;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 4px;
  width: 58%;
  
}

.left_grid {
  width: 50%;
}

.right_grid {
  width: 50%;
}

.form {
  display: flex;
  flex-direction: column;
  padding: 5rem;
}

.form h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

a,
button {
  background-color: transparent;
  border: none;
  color: #1890ff;
  cursor: pointer;
  margin-bottom: 20px;
  color: #2f2f2f;
  text-align: left;
}

.login_button {
  width: 100%;
  background-color: #2f4f4f;
  border: none;
  color: white;
}

.login_button:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
  font-weight: 500;
}

.right_grid img {
  max-width: 100%;
  height: 100%;
}

.ant-btn-primary {
  width: 100%;
}

p {
  margin-top: 1px;
  align-items: center;
}

.signup_now {
  text-align: center;
  font-weight: 500;
}

p.R_para {
  position: absolute;
  align-items: center;
  top: 45%;
  left: 55%;
  font-weight: 700;
  font-size: 25px;
  color: white;
}

@media (max-width: 768px) {
  .right_grid {
    display: none;
    width: 0%;
  }
  .left_grid {
    width: 100%;
  }
  .form {
    padding: 1rem;
  }
  .box_container {
    width: 80%;
    background-color: #D0A2F7;
    
  }
  .main_container
  {
    background: url(../assets/mobile-bg.png);
  }
}
