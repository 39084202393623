* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signup {
  display: flex;
  justify-content: center;
  padding-bottom: 2.5rem;
  font-size: 150%;
  font-weight: bold;
  color: rgb(0, 0, 0);
}
.react-tel-input .form-control {
  width: 350px !important;
}

.app-main {
  background-color: #2f4f4f;
}
.app {
  display: flex;
  justify-content: center;
}

.box {
  background-color: #ff88ff90;
  padding: 3.5rem;
}

.firstLast {
  display: flex;
}

.checkbox {
  color: white;
  font-weight: 400;
}

.button_container {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  background-color: #ffffff;
  color: black;
  font-weight: 500;
  padding-right: 8rem;
  padding-left: 8rem;
}

.anchor {
  background: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  color: rgb(0, 0, 0);
  font-family: "Poppins", sans-serif;
}

.existing {
  font-weight: 400;
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 110%;
}

.right-main {
  width: 40%;
}

.Main-container {
  padding: 5.4rem 7rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.right-main img {
  width: 100%;
}

@media (max-width: 768px) {
  .app-main {
    flex-direction: column;
    background: url(../assets/mobile-bg.png);
  }

  .Main-container {
    padding: 12rem;
  }

  .left-main {
    width: 100%;
  }

  .right-main {
    display: none;
  }

  .main-form {
    width: 100%;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .button {
    padding: 0 10px;
  }
}

@media (min-width: 769px) {
  .right-main {
    display: block;
  }
}
