.delete-button
{
    background-color: crimson;
    color: white;
}

.edit-button
{
    background-color: #3fc2ff;
    color: white;
}

.delete-button:hover{
    background-color: white;
    border: 1px solid black;
    color: black;
}

.edit-button:hover{
    background-color: white;
    border: 1px solid black;
    color: black;
}
.ant-table .ant-table-thead .ant-table-cell
{
    background-color:#d81d94;
    color: white;
}

.ant-table-cell
{
    background-color: #fdeaf7;
}

.table-view
{
    margin: 2%;
    border-radius: 2px;
}

.ant-card
{
    width: 100%;
    border-radius: 5px;
    background-color: rgb(238, 238, 238);
}

.ant-card-body
{
   
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  
}

.ant-card .ant-card-head
{
    background-color: #858585;
    color: white;
}

.ant-modal-footer
{
    display: flex;
    justify-content: center;

}
.ant-modal-confirm-btns
{
    display: flex;
    justify-content: center;
}

.ant-modal-footer .ant-btn
{
    width: 40%;
}

.ant-modal-content
{
    width: fit-content;
}


.ant-table-content {
  border: 1px solid rgb(135, 135, 135);
  border-radius: 10px;
}

@media (min-width: 768px) {
  .table-view {
    display: block;
  }
  .card-view {
    display: none;
  }
}
@media (max-width: 767px){
    .card-view{
        margin: 2rem;
        display: block;
        display: flex;
        justify-content: center;
      
        
    }
    .table-view{
        display: none;
    }
    .ant-card{
        box-shadow: rgba(100, 63, 87, 0.35) 0px 5px 15px;
        display: flex;
    }
    .admin-card
    {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        

    }
    

}