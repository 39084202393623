.main_box {
  height: 100vh;
}

.top_box {
  height: 50%;
  background-color: #7b68ee;
  display: flex;
  justify-content: center;
}

img {
  height: 100%;
  width: 100%;
}

.reset_box {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  height: 120%;
  width: 50%;
  text-align: center;
  background-color: white;
  margin-top: 11rem;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.reset-form {
  width: 40%;
}

h2 {
  margin-bottom: 1rem;
}
.reset_button {
  width: 100%;
  background-color: #7b68ee;
  border: none;
  color: white;
  font-weight: 500;
}

.reset_button:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
  font-weight: 500;
}

.icon {
  scale: 400%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .reset-left {
    display: none;
  }

  .reset-form {
    width: fit-content;
  }

  .reset_box {
    width: max-content;
  }
}
