
.start {
  width: 100%;
  background-color: #ed9dff;
  color: black;
}
.frontpage {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;

}

.front-main
{
  background-color: #311354;
}

.bg-img
{
 scale: 75%;
 padding-left: 2rem;
}

.front-right
{
  display: flex;
  flex-direction: column;
 justify-content: center;
 padding-right: 18rem;
 gap: 1rem;
}

.front-header
{
  color: white;
  font-size: 3rem;
}

@media (max-width: 767px)
{
  .frontpage
  {
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 13rem;
  }

  .bg-img
  {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .front-right
  {
    align-items: center;
    padding-right: 0;
  }
}


